import Link from '@/components/Link';
import type { bemFunction } from '@gik/core/utils/bemBlock';
import bemBlock from '@gik/core/utils/bemBlock';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import type { Placement } from '@popperjs/core';
import React from 'react';
import useLatest from 'react-use/lib/useLatest';
import type { SubMenu } from './Menu';
import { Menu } from './Menu';
import type { IMenuItem } from './MenuItem';

const blockName = 'menu-bar';

type MenuListItem = {
  title: string | React.ReactElement;
  menu?: Array<IMenuItem | SubMenu>;
  appendIcon?: SvgrComponent;
  prependIcon?: SvgrComponent;
  href?: string;
  shallow?: boolean;
  search?: boolean;
  categorize?: boolean;
  sortCategories?: boolean;
};

export interface IMenuBarProps extends React.HTMLAttributes<HTMLElement> {
  menuList: MenuListItem[];
  hasArrowIndicator?: boolean;
  placement?: Placement;
  className?: string;
}

export type IMenuBarElement = HTMLElement & {
  closeAll: () => void;
};

export function MenuBar({ menuList, className, hasArrowIndicator = false, placement, ...otherProps }: IMenuBarProps) {
  const bem = bemBlock(blockName);

  const [openItem, setOpenItem] = React.useState<number>(null);
  const openItemLatest = useLatest<number>(openItem);

  const closeAll = () => setOpenItem(null);

  const ref = React.useRef<HTMLElement>();

  React.useImperativeHandle(ref, () => ({
    closeAll,
    ...ref.current,
  }));

  return (
    <nav
      ref={ref}
      className={bem(
        null,
        [
          {
            open: openItemLatest.current !== null,
          },
        ],
        className
      )}
      {...otherProps}
    >
      {menuList.map((menu, i) => (
        <MenuBarItem
          key={i}
          menu={menu}
          i={i}
          bem={bem}
          openItem={openItemLatest.current}
          setOpenItem={setOpenItem}
          hasArrowIndicator={menu.menu && hasArrowIndicator}
          placement={placement}
        />
      ))}
    </nav>
  );
}

interface IMenuBarItemProps {
  menu: MenuListItem;
  i: number;
  bem: bemFunction;
  openItem: number;
  setOpenItem: React.Dispatch<React.SetStateAction<number>>;
  hasArrowIndicator?: boolean;
  placement?: Placement;
}

function MenuBarItem({ menu, i, bem, openItem, setOpenItem, hasArrowIndicator = false, placement }: IMenuBarItemProps) {
  const itemTitle = (
    <header className={bem('item-title')} onClick={() => setOpenItem(i)}>
      {menu.prependIcon && <SvgIcon className={bem('icon', [{ prepend: true }])} Icon={menu.prependIcon} />}

      <Link href={menu.href || '#'} className={bem('title')}>
        {menu.title}
      </Link>

      {menu.appendIcon && <SvgIcon className={bem('icon', [{ append: true }])} Icon={menu.appendIcon} />}
    </header>
  );

  return (
    <Menu
      trigger={itemTitle}
      isOpen={openItem === i}
      onClose={() => setOpenItem(null)}
      onHover={() => setOpenItem(i)}
      className={bem('item-menu')}
      items={menu.menu}
      search={menu.search}
      categorize={menu.categorize}
      sortCategories={menu.sortCategories}
      hasArrowIndicator={hasArrowIndicator}
      placement={placement}
    />
  );
}
