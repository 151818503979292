import Link from '@/components/Link';
import { usePostsFeatured } from '@gik/api/posts/postsFeatured';
import routes from '@gik/core/routes';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { CreateClickedLocation } from '@gik/create/enums/CreateAnalyticsEnums';
import { useCreateAnalyticsController } from '@gik/create/hooks/useCreateAnalyticsController';
import { Grid } from '@gik/ui/Grid';
import { HoverAnimation } from '@gik/ui/HoverAnimation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FacebookIconWhite from '../assets/facebook.svg';
import GikLogoWhite from '../assets/gik-logo-white.svg';
import InstagramIconWhite from '../assets/instagram.svg';
import PinterestIconWhite from '../assets/pinterest.svg';
import TwitterIconWhite from '../assets/twitter.svg';
import YoutubeIconWhite from '../assets/youtube.svg';
import { translationKeys } from './i18n/en';

const bem = bemBlock('app-footer');

interface FooterLink {
  title: string;
  url: string;
  scroll?: boolean;
  trackingFunction?: () => void;
}

interface FooterLinkListProps {
  title: string;
  links: FooterLink[];
}

function FooterLinkList({ title, links }: FooterLinkListProps) {
  return (
    <div className={bem('grid-link-list')}>
      <p>{title}</p>
      {links.map(({ title, url, trackingFunction = noop }) => (
        <Link key={url} href={url} className={bem('grid-link')}>
          <p onClick={trackingFunction}>{title}</p>
        </Link>
      ))}
    </div>
  );
}

interface SocialMediaLink {
  icon: React.ReactNode;
  url: string;
  ariaLabel: string;
}

const socialMediaLinks: SocialMediaLink[] = [
  { icon: <FacebookIconWhite />, url: 'https://www.facebook.com/giveinkind/', ariaLabel: 'Visit our Facebook page' },
  { icon: <TwitterIconWhite />, url: 'https://twitter.com/giveinkind', ariaLabel: 'Visit our Twitter page' },
  { icon: <InstagramIconWhite />, url: 'https://www.instagram.com/giveinkind/', ariaLabel: 'Visit our Instagram page' },
  { icon: <PinterestIconWhite />, url: 'https://www.pinterest.com/giveinkind', ariaLabel: 'Visit our Pinterest page' },
  {
    icon: <YoutubeIconWhite />,
    url: 'https://www.youtube.com/channel/UCGp15GUbx5bFwxQwAQUpnRw',
    ariaLabel: 'Visit our YouTube channel',
  },
];

export function AppFooter() {
  const { t } = useTranslation();

  const { trackCreateClicked } = useCreateAnalyticsController();
  const posts = usePostsFeatured({ slug: 'footer-help', perPage: 3, fields: ['title', 'slug'] });

  const footerLinkLists: FooterLinkListProps[] = [
    {
      title: t(translationKeys.inkind),
      links: [
        { title: t(translationKeys.about), url: routes.about },
        { title: t(translationKeys.contact), url: routes.contact },
        { title: t(translationKeys.support), url: routes.support },
        { title: t(translationKeys.press), url: 'https://create.giveinkind.com/press/' },
        // { title: t(translationKeys.write), url: 'https://create.giveinkind.com/call-for-submissions/' },
        // { title: t(translationKeys.healthCare), url: routes.healthcare }, //GIK-4713
      ],
    },
    {
      title: t(translationKeys.inkindPages),
      links: [
        {
          title: t(translationKeys.create),
          url: routes.create,
          trackingFunction: () => trackCreateClicked(CreateClickedLocation.PAGE_FOOTER),
        },
        { title: t(translationKeys.howItWorks), url: routes.howItWork },
        { title: t(translationKeys.features), url: routes.features },
        { title: t(translationKeys.compare), url: routes.competitors },
        { title: t(translationKeys.faq), url: routes.commonQuestions },
      ],
    },
    {
      title: t(translationKeys.howCanIHelp),
      links: [
        { title: t(translationKeys.browseSituations), url: routes.give, scroll: false },
        { title: t(translationKeys.blog), url: routes.blog },
        ...(posts?.data?.map(p => ({ title: p.title.rendered, url: `${routes.blog}/${p.slug}` })) || []),
      ],
    },
  ];

  function FinePrintLink({ title, url }: FooterLink) {
    return <Link href={url}>{title}</Link>;
  }

  const finePrint = (className: string) => (
    <div className={bem('fine-print', undefined, className)}>
      <p>
        {/* TODO: migrate to use LegalTextModal */}
        <FinePrintLink title={t(translationKeys.tos)} url={routes.termsService} /> •{' '}
        <FinePrintLink title={t(translationKeys.privacy)} url={routes.privacyPolicy} />
        {/* TODO: attribution - hidden for now*/
        /*<span className="gik-hidden-lg-down"> • </span>
        <FinePrintLink title={t(translationKeys.attribution)} url="/attribution" />*/}
      </p>
      <div className={bem('copyright')}>&copy;{new Date().getFullYear()} Give InKind. All Rights Reserved.</div>
    </div>
  );

  return (
    <footer className={bem()}>
      {finePrint('gik-hidden-lg')}
      <Grid className={bem('links-grid')}>
        {footerLinkLists.map(list => (
          <FooterLinkList key={list.title} {...list} />
        ))}
      </Grid>
      <div className={bem('right')}>
        <div className={bem('heading')}>
          <GikLogoWhite />

          <div className={bem('social-links')}>
            {socialMediaLinks.map(link => (
              <a key={link.url} href={link.url} target="_blank" rel="noreferrer" aria-label={link.ariaLabel}>
                <HoverAnimation>
                  <div className={bem('social-link')}>{link.icon}</div>
                </HoverAnimation>
              </a>
            ))}
          </div>
        </div>
        {finePrint('gik-hidden-lg-down')}
      </div>
    </footer>
  );
}
