import useMutatorObserver from '@gik/core/hooks/useMutatorObserver';
import React from 'react';
import useEffectOnce from 'react-use/lib/useEffectOnce';

const unbounceContainerElementClassName = 'ub-emb-container';

export function useHasUnbounceBar() {
  const [hasUnbounceBar, setHasUnbounceBar] = React.useState(false);
  const [targetNode, setTargetNode] = React.useState<Element>();

  function callback() {
    setHasUnbounceBar(targetNode?.children.length > 0);
  }

  useEffectOnce(
    () =>
      void setTimeout(() => {
        const targetNode = document?.getElementsByClassName(unbounceContainerElementClassName)?.[0]?.children?.item(0);
        setTargetNode(targetNode);
        setHasUnbounceBar(targetNode?.children.length > 0);
      }, 10)
  );

  useMutatorObserver(targetNode, callback, { attributes: true, childList: true, subtree: true });

  return hasUnbounceBar;
}
