import React from 'react';

export default function useMutatorObserver(
  targetNode: Node,
  callback: MutationCallback,
  options?: MutationObserverInit
) {
  React.useEffect(() => {
    const observer = new MutationObserver(callback);
    targetNode && observer.observe(targetNode, options);
    return () => void observer?.disconnect();
  }, [targetNode, callback, options]);
}
