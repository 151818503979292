import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type { IOAuthUserLoginResponse, IUserLoginResponse } from '@gik/api/users/types';
import type { IForgotPasswordFormValues } from '@gik/auth/components/ForgotPassword/ForgotPasswordForm';
import { translationKeys } from '@gik/auth/components/SignInFlow/i18n/en';
import { SigninFlowAccountDeactivated } from '@gik/auth/components/SignInFlow/SigninFlowAccountDeactivated';
import { SignInFlowOAuthContent } from '@gik/auth/components/SignInFlow/SignInFlowOAuthContent';
import { SignInFlowStartContentFormInputNames } from '@gik/auth/enums/SignInFlowStartContentFormInputNames';
import type { ISignInFlowModalHeaderRef } from '@gik/auth/utils/authModals';
import type { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { WalletSignInFlowLoginContent } from './WalletSignInFlowLoginContent';
import { WalletSignInFlowStartContent } from './WalletSignInFlowStartContent';
import { WalletSignInFlow2fa } from './WalletSignInFlow2fa';

export enum WalletSignInFlowStep {
  START = 'start',
  LOGIN = 'login',
  OAUTH = 'oauth',
  TWOFACTORAUTH = '2fa',
  DEACTIVATED = 'deactivated',
}

const WalletSignInFlowStepHeaderTitleTranslationKeyMap: Record<WalletSignInFlowStep, string> = {
  [WalletSignInFlowStep.START]: translationKeys.headerTitleStart,
  [WalletSignInFlowStep.LOGIN]: translationKeys.headerTitleLogin,
  [WalletSignInFlowStep.OAUTH]: translationKeys.headerTitleOAuth,
  [WalletSignInFlowStep.TWOFACTORAUTH]: translationKeys.headerTitle2fa,
  [WalletSignInFlowStep.DEACTIVATED]: translationKeys.headerTitleDeactivated,
};

export type WalletSignInFlowInitialValues = {
  [SignInFlowStartContentFormInputNames.EmailAddress]: string;
  provider?: ExternalLoginProvider;
  firstName?: string;
};

export type IWalletSignInFlowContentProps = UIComponent & {
  startOn?: WalletSignInFlowStep;

  routeId?: string;
  trackingId?: AnalyticsEvents;
  onSuccess?: (values: IUserLoginResponse | IOAuthUserLoginResponse) => void;
  onForgotPassword?: (initialValues: Partial<IForgotPasswordFormValues>) => void;
  initialValues?: WalletSignInFlowInitialValues;
  headerRef?: React.RefObject<ISignInFlowModalHeaderRef>;
};

export function WalletSignInFlowContent({
  startOn,

  routeId,
  className,
  trackingId,
  onSuccess,
  onForgotPassword,
  initialValues,
  headerRef,
  ...otherProps
}: IWalletSignInFlowContentProps) {
  const bem = useBemCN('sign-in-flow-content');
  const [currentStep, setCurrentStep] = React.useState<WalletSignInFlowStep>(startOn ?? WalletSignInFlowStep.START);
  const [email, setEmail] = React.useState<string>(
    initialValues?.[SignInFlowStartContentFormInputNames.EmailAddress] ?? null
  );
  const [firstName, setFirstName] = React.useState<string>(initialValues?.firstName ?? null);
  const [provider, setProvider] = React.useState<ExternalLoginProvider>(initialValues?.provider ?? null);

  React.useEffect(() => {
    headerRef?.current?.setTitle(WalletSignInFlowStepHeaderTitleTranslationKeyMap[currentStep]);
  }, [currentStep, headerRef]);

  function goToStartStep() {
    setEmail(null);
    setFirstName(null);
    setProvider(null);
    setCurrentStep(WalletSignInFlowStep.START);
  }

  function goToLoginStep(email: string, firstName: string) {
    setEmail(email);
    setFirstName(firstName);
    setCurrentStep(WalletSignInFlowStep.LOGIN);
  }

  function goTo2faStep(email: string) {
    setEmail(email);
    setCurrentStep(WalletSignInFlowStep.TWOFACTORAUTH);
  }

  function goToAccountDeactivatedStep(email: string, firstName: string) {
    setEmail(email);
    setFirstName(firstName);
    setCurrentStep(WalletSignInFlowStep.DEACTIVATED);
  }

  function goToOAuthStep(email: string, provider: ExternalLoginProvider, firstName: string) {
    setEmail(email);
    setProvider(provider);
    setFirstName(firstName);
    setCurrentStep(WalletSignInFlowStep.OAUTH);
  }

  // const onIncorrectProvider = React.useCallback(async (email: string) => {
  //   await verifyEmailAndRedirectToLoginProvider(email, goToLoginStep, goToAccountDeactivatedStep, goToOAuthStep);
  // }, []);

  return (
    <div {...bem(null, currentStep ?? WalletSignInFlowStep.START, className)} {...otherProps}>
      <TransitionGroup {...bem('transition')}>
        {currentStep === WalletSignInFlowStep.LOGIN && (
          <CSSTransition id={WalletSignInFlowStep.LOGIN} timeout={200} classNames={bem('transition').className}>
            <WalletSignInFlowLoginContent
              supportersFirstName={firstName}
              initialValues={{
                email,
              }}
              goToStartStep={goToStartStep}
              trackingId={trackingId}
              onSuccess={onSuccess}
              onForgotPassword={onForgotPassword}
            />
          </CSSTransition>
        )}

        {currentStep === WalletSignInFlowStep.OAUTH && (
          <CSSTransition id={WalletSignInFlowStep.OAUTH} timeout={200} classNames={bem('transition').className}>
            <SignInFlowOAuthContent
              email={email}
              provider={provider}
              supportersFirstName={firstName}
              onSuccess={onSuccess}
              goToStartStep={goToStartStep}
              // onIncorrectProvider={onIncorrectProvider}
            />
          </CSSTransition>
        )}

        {currentStep === WalletSignInFlowStep.TWOFACTORAUTH && (
          <CSSTransition id={WalletSignInFlowStep.TWOFACTORAUTH} timeout={200} classNames={bem('transition').className}>
            <WalletSignInFlow2fa
              onSuccess={onSuccess}
              // goToStartStep={goToStartStep}
              // onIncorrectProvider={onIncorrectProvider}
            />
          </CSSTransition>
        )}

        {currentStep === WalletSignInFlowStep.START && (
          <CSSTransition id={WalletSignInFlowStep.START} timeout={200} classNames={bem('transition').className}>
            <WalletSignInFlowStartContent
              routeId={routeId}
              goToLoginStep={goToLoginStep}
              goTo2faStep={goTo2faStep}
              goToOAuthStep={goToOAuthStep}
              goToAccountDeactivatedStep={goToAccountDeactivatedStep}
              onSuccess={onSuccess}
              // onIncorrectProvider={onIncorrectProvider}
              initialValues={initialValues}
            />
          </CSSTransition>
        )}

        {currentStep === WalletSignInFlowStep.DEACTIVATED && (
          <CSSTransition id={WalletSignInFlowStep.DEACTIVATED} timeout={200} classNames={bem('transition').className}>
            <SigninFlowAccountDeactivated goToStartStep={goToStartStep} supportersFirstName={firstName} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
}
