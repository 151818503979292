import { breakpointBem } from '@gik/core/hooks/hooks/BreakpointHooks';
import { useIsMounted } from '@gik/core/hooks/hooks/useIsMounted';
// import { useIsMounted } from '@gik/core/hooks/hooks/useIsMounted';
import routes from '@gik/core/routes';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { SearchButton } from '@gik/search/components';
import { Button } from '@gik/ui/Button';
import type { IAppHeaderProps } from '@gik/ui/gik/AppHeader/AppHeader';
import { AppHeaderSignInButton } from '@gik/ui/gik/AppHeader/AppHeaderSignInButton';
import { SvgIcon } from '@gik/ui/SvgIcon';
import HomeIcon from '@heroicons/react/outline/HomeIcon';
import React from 'react';

export function AppHeaderNavMobile({ breakpoint }: IAppHeaderProps) {
  const bem = useBemCN('app-header-nav-mobile');
  const userId = useUserStore(state => state.id);
  const isMounted = useIsMounted();

  return (
    <ul {...bem(null, breakpointBem(breakpoint))}>
      <li>
        <SearchButton />
      </li>
      {!userId && isMounted() && <AppHeaderSignInButton breakpoint={breakpoint} />}
      {userId && isMounted() && (
        <li>
          <Button circle variant="secondary" href={routes.userProfile}>
            <SvgIcon Icon={HomeIcon} />
          </Button>
        </li>
      )}
    </ul>
  );
}
