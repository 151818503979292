import { navigateTo } from '@/utils/navigateTo';
import { timeoutDefaultValue } from '@gik/core/constants';
import bemBlock from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { SearchInput } from '@gik/ui/SearchInput';
import { Sticky } from '@gik/ui/Sticky';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronLeft from '@heroicons/react/solid/ChevronLeftIcon';
import ChevronRight from '@heroicons/react/solid/ChevronRightIcon';
import type { Placement } from '@popperjs/core';
import dynamic from 'next/dynamic';
import React, { Fragment } from 'react';
import type { IMenuItem, MenuItemClickable, MenuItemHref } from '.';
import { MenuCategoryTitle, MenuItem } from '.';
import type { PopoverProps } from '../Popover';

const blockName = 'menu';

interface BaseMenu {
  items?: IMenuItem[];
  search?: boolean;
  categorize?: boolean;
  sortCategories?: boolean;
  hasArrowIndicator?: boolean;
  placement?: Placement;
}

export interface IMenuProps extends BaseMenu {
  items?: Array<IMenuItem | SubMenu>;
  isOpen: boolean;
  onClose: () => void;
  onHover: () => void;
  trigger: React.ReactElement;
  className?: string;
}

export interface SubMenu extends BaseMenu, MenuItemClickable {}

export function Menu({
  items,
  className,
  isOpen,
  onClose,
  trigger,
  onHover,
  search = false,
  categorize = false,
  sortCategories = false,
  hasArrowIndicator = false,
  placement,
}: IMenuProps) {
  const bem = bemBlock(blockName);

  const [query, setQuery] = React.useState<string>();
  const [parent, setParent] = React.useState<IMenuItem>(null);
  const [_items, _setItems] = React.useState<IMenuItem[]>(items);
  const [_search, _setSearch] = React.useState<boolean>(search);
  const [_categorize, _setCategorize] = React.useState<boolean>(categorize);
  const [_sortCategories, _setSortCategories] = React.useState<boolean>(sortCategories);

  const categories = React.useMemo(() => {
    if (_categorize && _items) {
      const categories: string[] = [];

      _items.forEach(({ category }) => {
        if (!categories.includes(category)) {
          categories.push(category);
        }
      });

      if (_sortCategories) {
        return categories.sort((a, b) => a.localeCompare(b));
      } else {
        return categories;
      }
    } else {
      return null;
    }
  }, [_categorize, _items, _sortCategories]);

  function onClickExpandItem(item: SubMenu) {
    setParent(item);
    _setItems(item.items);
    _setSearch(item.search);
    _setCategorize(item.categorize);
    _setSortCategories(item.sortCategories);
  }

  function onClickBack() {
    setParent(null);
    _setItems(items);
    _setSearch(search);
    _setCategorize(categorize);
    _setSortCategories(sortCategories);
  }

  const Popover = dynamic<PopoverProps>(() => import('@gik/ui/Popover/Popover').then(mod => mod.Popover), {
    loading: () => <>{trigger}</>,
    ssr: false,
  });

  const navigationButton = (
    <Button circle variant={'default-plain'} onClick={() => onClickBack()}>
      <SvgIcon className={bem('icon', [{ append: true }])} Icon={ChevronLeft} />
    </Button>
  );

  const _onClose = React.useCallback(() => {
    setParent(null);
    _setItems(items);
    _setSearch(search);
    _setCategorize(categorize);
    _setSortCategories(sortCategories);

    onClose?.();
    //eslint-disable-next-line
  }, []);

  const handleClick = React.useCallback(
    item => {
      _onClose();
      setTimeout(() => {
        navigateTo(item.href, {
          scroll: item.scroll === undefined ? true : item.scroll,
          shallow: item.shallow || true,
        });
      }, timeoutDefaultValue);
    },
    [_onClose]
  );

  const memoizedItems = React.useMemo(() => {
    const bem = bemBlock(blockName);

    function generateItem(item, key) {
      return (
        <MenuItem
          className={bem('item')}
          key={key}
          item={
            (item as SubMenu)?.items
              ? {
                  ...item,
                  onClick: () => onClickExpandItem(item as SubMenu),
                  icon: ChevronRight,
                }
              : {
                  ...item,
                  onClick: () => handleClick(item),
                }
          }
        />
      );
    }

    if (_categorize) {
      return categories
        ?.filter(category => category?.length > 0)
        .map((category, key) => (
          <Fragment key={key}>
            {!query && (
              <MenuCategoryTitle
                item={{
                  label: category,
                }}
              />
            )}
            {_items
              ?.filter(item => item.category === category)
              .filter(item =>
                query ? !item.hideOnSearch && (item.label as string).toUpperCase().includes(query.toUpperCase()) : true
              )
              .map(generateItem)}
          </Fragment>
        ));
    } else {
      return _items
        ?.filter(item =>
          query ? !item.hideOnSearch && (item.label as string).toUpperCase().includes(query.toUpperCase()) : true
        )
        .map(generateItem);
    }
  }, [_categorize, _items, categories, query, handleClick]);

  return (
    <>
      <Popover
        className={bem(null, [{ categorize: _categorize }, { search }], className)}
        trigger={trigger}
        isOpen={isOpen}
        onClose={_onClose}
        onHover={onHover}
        hasArrowIndicator={hasArrowIndicator}
        placement={placement}
        overlap
        hoverable
        noPad
      >
        {parent && !_search && (
          <Sticky>
            <div className={bem('menu-nav-bar')}>{navigationButton}</div>
          </Sticky>
        )}

        {_search && (
          <Sticky>
            <div className={bem('search-bar')}>
              <SearchInput
                value={query}
                onValueChange={value => setQuery(value)}
                placeholder={'Search tags'}
                autoFocus
              />
              {parent && navigationButton}
            </div>
          </Sticky>
        )}

        {memoizedItems}
      </Popover>

      {/* TODO: submenus */}
      {items && (
        <ul className={bem('seo-navbar')}>
          {items.map((item, key) => (
            <li key={`${key}-${item.label}`}>
              {/* <Link href={(item as MenuItemHref).href}> */}
              <a onClick={() => handleClick((item as MenuItemHref).href)}>{item.label}</a>
              {/* </Link> */}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
