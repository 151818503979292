import routes from '@gik/core/routes';
import i18n from '@gik/i18n';
import { translationKeys } from '@gik/i18n/en/app';
import ChevronDown from '@heroicons/react/solid/ChevronDownIcon';

export const whyGiveInkindItems = [
  { label: i18n.t(translationKeys.howItWorks), href: routes.howItWork },
  { label: i18n.t(translationKeys.features), href: routes.features },
  // { label: i18n.t(translationKeys.comparisonChart), href: routes.competitors },
  // { label: i18n.t(translationKeys.benefits), href: routes.benefits },
  { label: i18n.t(translationKeys.tipsAndSamplePages), href: routes.tipsAndSamplePages },
  { label: i18n.t(translationKeys.commonQuestions), href: routes.commonQuestions },
  // { label: i18n.t(translationKeys.healthCare), href: routes.healthcare }, //GIK-4713
];

export const waysToHelpItems = [
  { label: i18n.t(translationKeys.bySituation), href: routes.give, scroll: false },
  { label: i18n.t(translationKeys.byGiftType), href: routes.byGiftType, scroll: false },
  { label: i18n.t(translationKeys.blog), href: routes.blog },
  { label: i18n.t(translationKeys.giftGuides), href: routes.giftGuides },
];

export const forGroupsItems = [
  { label: i18n.t(translationKeys.forEmployers), href: routes.employers },
  { label: i18n.t(translationKeys.forFaithGroups), href: routes.church },
  { label: i18n.t(translationKeys.forHealthcare), href: routes.healthcareGroup },
];

export const menuBar = [
  {
    title: 'Why Give InKind?',
    appendIcon: ChevronDown,
    menu: whyGiveInkindItems,
  },
  {
    title: 'Ways to Help',
    appendIcon: ChevronDown,
    menu: waysToHelpItems,
  },
  {
    title: i18n.t(translationKeys.forGroups),
    appendIcon: ChevronDown,
    menu: forGroupsItems,
  },
];
