import type { BreakpointType } from '@gik/core/hooks/hooks/BreakpointHooks';
import { breakpointBem } from '@gik/core/hooks/hooks/BreakpointHooks';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { translationKeys } from '@gik/i18n/en/app';
import { Button } from '@gik/ui/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function AppHeaderSignInButton({ breakpoint }: BreakpointType) {
  const bem = useBemCN('app-header-sign-in-button');
  const userId = useUserStore(state => state.id);
  const { t } = useTranslation();

  async function handleClickSignIn() {
    const { signin } = await import('@gik/auth/utils/auth');
    await signin();
  }

  return (
    !userId && (
      <li {...bem(null, breakpointBem(breakpoint))}>
        <Button variant="default-link" {...bem('btn-signin')} onClick={handleClickSignIn} uppercase={false}>
          {t(translationKeys.signin).toString()}
        </Button>
      </li>
    )
  );
}
