import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useInkind } from '@gik/api/inkinds/inkind';
import type { IUserLoginResponse } from '@gik/api/users/types';
import { translationKeys } from '@gik/auth/components/WalletSignInFlow/i18n/en';
import { SignInFlowStartContentFormInputNames } from '@gik/auth/enums/SignInFlowStartContentFormInputNames';
import { walletSignInFlow2faFormSchema } from '@gik/auth/formSchemas';
import type { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { Form, FormField } from '@gik/ui/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReloadIcon from '@gik/core/assets/img/icons/reload.svg';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { UI } from '@gik/ui/UIManager';

interface IWalletSignInFlow2faValues {
  [SignInFlowStartContentFormInputNames.EmailAddress]: string;
}

export type IWalletSignInFlow2faProps = UIComponent & {
  routeId?: string;
  goToLoginStep?: (email: string, firstName: string) => void;
  goToAccountDeactivatedStep?: (email: string, firstName: string) => void;
  goTo2faStep?: (email: string) => void;
  goToOAuthStep?: (email: string, provider: ExternalLoginProvider, firstName: string) => void;
  initialValues?: IWalletSignInFlow2faValues;
  trackingId?: AnalyticsEvents;
  onChange?: (values: IWalletSignInFlow2faValues) => void;
  onSuccess(values: IUserLoginResponse);
  onIncorrectProvider?: (email: string) => Promise<void>;
};

export function WalletSignInFlow2fa({
  routeId,
  goToLoginStep,
  goToAccountDeactivatedStep,
  goToOAuthStep,
  goTo2faStep,
  className,
  trackingId,
  initialValues,
  onChange,
  onSuccess,
  onIncorrectProvider,
  ...otherProps
}: IWalletSignInFlow2faProps) {
  const bem = useBemCN('wallet-sign-in-flow-start-content');
  const { t } = useTranslation();
  const { data: inkindPage } = useInkind(routeId);

  const isResendSubmitting = false;

  async function handleSubmit(values: IWalletSignInFlow2faValues) {
    const { [SignInFlowStartContentFormInputNames.EmailAddress]: email } = values;

    // TODO: call dotnet to verify code

    console.log('values', values);
  }

  const handleResendCode = React.useCallback(() => {
    // TODO: call dotnet to resend code
    UI.alert('The verification code has been sent to your email', { title: 'Email Sent' });
  }, []);

  return (
    <section {...bem(undefined, undefined, className)} {...otherProps}>
      <h1
        {...bem(
          'title',
          undefined,
          'tw-font-semibold tw-text-4xl tw-leading-none tw-text-neutral-900 tw-text-center tw-mt-0 tw-mb-3'
        )}
      >
        {t(translationKeys.twofaTitle).toString()}
      </h1>

      <p
        {...bem(
          'copy',
          undefined,
          'tw-font-normal tw-text-base tw-leading-tight tw-text-neutral-900 tw-text-center tw-mb-8'
        )}
      >
        {t(translationKeys.twofaCopy).toString()}
      </p>

      <Form
        {...bem('form')}
        schema={walletSignInFlow2faFormSchema}
        trackingId={trackingId}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onChange={onChange}
        render={({ isSubmitting, isValid }) => {
          return (
            <>
              <fieldset className="tw-mb-4">
                <FormField name={'code'} />
                <FormField name={'remember'} />
              </fieldset>

              <Button
                {...bem('btnSubmit', undefined, 'tw-mt-4')}
                variant="primary"
                type="submit"
                size="lg"
                loading={isSubmitting}
                disabled={!isValid}
                fullWidth
              >
                Confirm
              </Button>

              <div className="tw-text-center">
                <Button
                  {...bem('btnSubmit', undefined, 'tw-mx-auto')}
                  variant="transparent"
                  size="lg"
                  loading={isResendSubmitting}
                  onClick={handleResendCode}
                  prepend={<SvgIcon Icon={ReloadIcon} />}
                >
                  Resend Code
                </Button>
              </div>
            </>
          );
        }}
      />
    </section>
  );
}
