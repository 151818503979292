import Link from '@/components/Link';
import Logo from '@gik/core/assets/img/icons/giveinkind-logo.svg';
import { breakpointBem } from '@gik/core/hooks/hooks/BreakpointHooks';
import routes from '@gik/core/routes';
import { useBemCN } from '@gik/core/utils/bemBlock';
import type { IAppHeaderProps } from '@gik/ui/gik/AppHeader/AppHeader';
import React from 'react';

export function AppHeaderLogo({ breakpoint }: IAppHeaderProps) {
  const bem = useBemCN('app-header-logo');

  return (
    <div {...bem(null, breakpointBem(breakpoint))}>
      <Link href={routes.home} aria-label="Go to Homepage">
        <Logo />
      </Link>
    </div>
  );
}
