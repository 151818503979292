import type { BreakpointType } from '@gik/core/hooks/hooks/BreakpointHooks';
import { breakpointBem } from '@gik/core/hooks/hooks/BreakpointHooks';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import { type IWalletSignInFlowContentProps, WalletSignInFlowContent } from './WalletSignInFlowContent';

export type IWalletSignInFlowProps = UIComponent & BreakpointType & IWalletSignInFlowContentProps;

export function WalletSignInFlow({
  startOn,

  routeId,
  className,
  breakpoint,
  onSuccess,
  onForgotPassword,
  initialValues,
  trackingId,
  headerRef,
  ...otherProps
}: IWalletSignInFlowProps) {
  const bem = useBemCN('sign-in-flow');

  return (
    <article {...bem(null, breakpointBem(breakpoint), className)} {...otherProps}>
      <main {...bem('content-container')}>
        <WalletSignInFlowContent
          startOn={startOn}
          routeId={routeId}
          trackingId={trackingId}
          onSuccess={onSuccess}
          onForgotPassword={onForgotPassword}
          initialValues={initialValues}
          headerRef={headerRef}
        />
      </main>
    </article>
  );
}
