import Link from '@/components/Link';
import { navigateTo } from '@/utils/navigateTo';
import type { Hit } from '@algolia/client-search';
import { logout, signin } from '@gik/auth/utils/auth';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import routes from '@gik/core/routes';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { translationKeys } from '@gik/i18n/en/app';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import type { InkindAlgoliaObject } from '@gik/search/components';
import { PageRowsSection } from '@gik/ui/gik/PageRows/PageRowsSection';
import { useInkindAPIOrAlgolia } from '@gik/user-profile/hooks/useInkindAPIOrAlgolia';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IUserNavProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserNav({ className }: React.PropsWithChildren<IUserNavProps>): React.ReactElement {
  const bem = bemBlock('user-nav');
  const { t } = useTranslation();

  const router = useRouter();

  const userId = useUserStore(state => state.id);
  const setInkindRouteId = useInkindStore(state => state.setInkindRouteId);

  function handlePageSelect(page: InkindPageAPIModel | Hit<InkindAlgoliaObject>) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { routeId, slug } = useInkindAPIOrAlgolia(page);
    setInkindRouteId(routeId);
    // navigateTo(`${routes.inkinds}/${routeId}/${slug}`);
  }

  function handleLogout() {
    if (router.route === '/user/profile') {
      // the user should be logged out after navigation to homepage is completed
      useUserStore.getState().setShouldLogout(true);
      navigateTo(routes.home);
      return;
    }

    // normal logout
    logout(true);
  }

  return (
    <ul className={bem(null, null, className)}>
      <li>
        <PageRowsSection onClick={handlePageSelect} />
      </li>

      {!userId && (
        <li>
          <a className={bem('btn-signin')} onClick={() => signin()}>
            {t(translationKeys.signin).toString()}
          </a>
        </li>
      )}
      {userId && (
        <>
          <li>
            <Link href={routes.userProfile}>{t(translationKeys.viewEditProfile).toString()}</Link>
          </li>
          <li>
            <a onClick={handleLogout}>
              <span className="tw-text-danger-500">{t(translationKeys.logout).toString()}</span>
            </a>
          </li>
        </>
      )}
    </ul>
  );
}
