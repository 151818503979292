import Link from '@/components/Link';
import { useUser } from '@gik/api/users/user';
import routes from '@gik/core/routes';
import { useUserStore } from '@gik/core/store/UserStore';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import { Avatar } from '@gik/ui/Avatar';
import { Button } from '@gik/ui/Button';
import type { PopoverProps } from '@gik/ui/Popover';
import dynamic from 'next/dynamic';
import React from 'react';
import { UserNav } from '../UserNav';

const Popover = dynamic<PopoverProps>(() => import('@gik/ui/Popover/Popover').then(mod => mod.Popover), {
  ssr: false,
});

export type IUserAvatarMenuProps = {
  isImpersonating?: boolean;
} & UIComponent;

export function UserAvatarMenu({
  isImpersonating,
  className,
}: React.PropsWithChildren<IUserAvatarMenuProps>): React.ReactElement {
  const bem = bemBlock('user-avatar-menu');

  const [isOpen, setOpen] = React.useState(false);
  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  return (
    <Popover
      className={bem('popover', null, className)}
      trigger={
        <div className={bem('avatar-wrapper')}>
          <Button variant="white-link" type="a" circle>
            <Link href={routes.userProfile} passHref>
              <Avatar
                isImpersonating={isImpersonating}
                loading={!user}
                key={user?.id}
                imageUrl={user?.avatarThumbSmallURL}
              />
            </Link>
          </Button>
        </div>
      }
      isOpen={isOpen}
      overlap
      onClose={() => setOpen(false)}
      onHover={() => {
        if (user) setOpen(true);
      }}
      hasArrowIndicator={false}
      placement="bottom-end"
      noPad
      hoverable
    >
      <UserNav />
    </Popover>
  );
}
