import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type { IUserLoginResponse } from '@gik/api/users/types';
import { LoginForm } from '@gik/auth';
import type { IForgotPasswordFormValues } from '@gik/auth/components/ForgotPassword/ForgotPasswordForm';
import type { IUserLoginFormValues } from '@gik/auth/components/Login/LoginForm';
import { translationKeys } from '@gik/auth/components/WalletSignInFlow/i18n/en';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Decoder } from '@gik/ui/Decoder';
import React from 'react';
import { useTranslation } from 'react-i18next';

type WalletSignInFlowLoginContentProps = UIComponent & {
  supportersFirstName: string;
  trackingId?: AnalyticsEvents;
  initialValues?: Partial<IUserLoginFormValues>;
  goToStartStep: () => void;
  onSuccess(values: IUserLoginResponse);
  onForgotPassword?: (initialValues: Partial<IForgotPasswordFormValues>) => void;
};

export function WalletSignInFlowLoginContent({
  supportersFirstName,
  trackingId,
  className,
  initialValues,
  goToStartStep,
  onSuccess,
  onForgotPassword,
  ...otherProps
}: WalletSignInFlowLoginContentProps) {
  const bem = useBemCN('sign-in-flow-login-content');
  const { t } = useTranslation();

  return (
    <section {...bem(null, null, className)} {...otherProps}>
      <h1 {...bem('title')}>
        <Decoder text={t(translationKeys.loginContentTitle, { supportersFirstName })} />
      </h1>
      <p {...bem('copy')}>{t(translationKeys.loginContentCopy).toString()}</p>

      <LoginForm
        trackingId={trackingId}
        initialValues={initialValues}
        hideSignup
        disableEmailField
        onSuccess={onSuccess}
        onUseDifferentEmail={goToStartStep}
        onForgotPassword={onForgotPassword}
      />
    </section>
  );
}
