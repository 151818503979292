import { VercelSpeedInsights } from '@/components/VercelSpeedInsights';
import { warnUnsavedChanges } from '@/constants';
import { logout } from '@gik/auth/utils/auth';
import { useAppStore } from '@gik/core/store/AppStore';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { appUpdateStartPolling, appUpdateStopPolling, checkAppVersion } from '@gik/core/utils/appUpdates';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { storage } from '@gik/core/utils/Storage';
import { useDialogStore } from '@gik/ui/Dialogs';
import { AppHeaderSticky } from '@gik/ui/gik/AppHeader/AppHeaderSticky';
import { StickyHorizontal } from '@gik/ui/Sticky';
import { UI } from '@gik/ui/UIManager';
import dynamic from 'next/dynamic';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import React from 'react';
import { AppFooter } from '../AppFooter';
import { QueryStringHandler } from '../QueryStringHandler/QueryStringHandler';

const IntercomBooter = dynamic(() => import('../IntercomLazyProvider/IntercomBooter').then(mod => mod.IntercomBooter), {
  ssr: false,
});

const IntercomIdentifier = dynamic(
  () => import('@gik/analytics/hooks/IntercomIdentifier').then(mod => mod.IntercomIdentifier),
  {
    ssr: false,
  }
);

const AppImports = dynamic(() => import('./AppImports').then(mod => mod.AppImports), {
  ssr: false,
});

interface AppLayoutProps extends React.PropsWithChildren<IPageLayoutAttachment> {
  hideHeader?: boolean;
  hideFooter?: boolean;
  hasAnimations?: boolean;
  groupSlug?: string;
  className?: string;
}

export interface IPageLayoutAttachment {
  navBarAttachment?: React.ReactNode;
  footerAttachment?: React.ReactNode;
}

const blockName = 'app-layout';

function AppLayoutComp({
  children,
  navBarAttachment,
  footerAttachment,
  hideHeader = false,
  hideFooter = false,
  className,
}: AppLayoutProps) {
  const bem = useBemCN(blockName);

  const router = useRouter();

  const routeChangeCompleteFn = () => {
    if (useUserStore.getState().shouldCloseModalsAfterNavigation) {
      // close all dialogs after a successful route navigation
      // use a timeout of 1 second to allow the new page to load first
      UI.closeAllDialogs();
    } else {
      // set shouldCloseModalsAfterNavigation to true again
      useUserStore.getState().setShouldCloseModalsAfterNavigation(true);
    }

    // log the user out after a navigation if shouldLogout is true
    if (useUserStore.getState().shouldLogout) {
      logout();
      useUserStore.getState().setShouldLogout(false);
    }

    if (useUserStore.getState().shouldUnblurAfterNavigation) {
      // unblurApp();
    } else {
      // set shouldUnblurAfterNavigation to true again
      useUserStore.getState().setShouldUnblurAfterNavigation(true);
    }
  };

  const routeChangeStartHandler = React.useCallback(
    (url, { shallow }) => {
      if (!router) return;
      const currentPath = router.asPath;

      // prevent navigation if nextjs is trying to navigate to the same route without shallow routing
      if (currentPath == url && !shallow) {
        NProgress.done();
        // NOTE: an error has to be thrown here and it can't be caught (we need to let the error through to nextjs)
        // this will result in a unhandled promise rejection error log being thrown in the console which can be ignored.
        throw new Error(`Route navigation aborted from ${currentPath} to ${url}`);
      }
    },
    [router]
  );

  React.useEffect(() => {
    Router.events.on('routeChangeStart', routeChangeStartHandler);
    return () => {
      Router.events.off('routeChangeStart', routeChangeStartHandler);
    };
  }, [routeChangeStartHandler]);

  React.useEffect(() => {
    // start polling for app updates
    if (useEnvStore.getState().APP_UPDATE_POLLING) {
      appUpdateStartPolling();
      // give the app a couple of seconds to boot and then peform an initial check
      setTimeout(() => {
        checkAppVersion();
      }, 3000);
    }

    window.onunload = () => {
      // store opened modals
      const dialogs = useDialogStore.getState().dialogs;
      if (dialogs.length && useAppStore.getState().isUpdating) {
        const dialogIds = dialogs.map(item => item.key).filter(item => item !== null);
        storage.set('open-dialogs', JSON.stringify(dialogIds));
      }
    };

    // register app level unbefore unload event
    window.onbeforeunload = () => {
      if (!warnUnsavedChanges) return;

      const dirtyForms = useAppStore.getState().dirtyForms;
      // skip this if a form is being submitted or none of the forms are dirty
      if (useAppStore.getState().isSubmitting || !dirtyForms.length) {
        return undefined;
      }

      // NOTE: You can't set custom message anymore in most modern browsers.
      // For consistency we we just use the same message as mordern browser would use.
      return 'Changes you made may not be saved.';
    };

    setTimeout(() => {
      storage.remove('open-dialogs');
    }, 5000);

    Router.events.on('routeChangeComplete', routeChangeCompleteFn);
    return () => {
      Router.events.off('routeChangeComplete', routeChangeCompleteFn);
      // stop polling for app updates
      if (useEnvStore.getState().APP_UPDATE_POLLING) appUpdateStopPolling();
    };
  }, []);

  return (
    // <AnimatePresence exitBeforeEnter initial={false} onExitComplete={() => window.scrollTo(0, 0)}>
    <>
      <VercelSpeedInsights />
      <IntercomBooter>
        <IntercomIdentifier />
      </IntercomBooter>
      <QueryStringHandler />

      <AppImports />
      <div {...bem(null, null, className)}>
        {!hideHeader && <AppHeaderSticky navBarAttachment={navBarAttachment} />}
        <main>{children}</main>
        {!hideFooter && (
          <StickyHorizontal>
            {footerAttachment}
            <AppFooter />
          </StickyHorizontal>
        )}
      </div>
    </>
    // </AnimatePresence>
  );
}

export const AppLayout = AppLayoutComp;
