import type { DeepQueryParams, QueryParams } from '@gik/core/api/BaseAPIConfig';
import getPreviewMode from '@gik/core/utils/PreviewMode';

interface PostsUrlBuilderParams {
  'gik-preview'?: number;
  slug?: string;
  author_slug?: string;
  perPage?: number;
  page?: number;
  orderby?: string;
  order?: string;
  meta_key?: string;
  meta_product_bundles?: number;
  meta_buying_guides?: number;
  filter?: DeepQueryParams;
  'proxy-cms-pages'?: 'all';
  _fields?: string;
  include?: string;
}

export interface RelationFilter<T = string | string[]> {
  taxonomy: string;
  terms: T;
  field?: string;
}

export class PostsUrlBuilder {
  private readonly _params: PostsUrlBuilderParams;
  private readonly defaultTaxonomyItemFieldValue = 'slug';

  constructor() {
    this._params = {};

    // TODO: previewMode should come from a cookie
    this.withPreviewMode(getPreviewMode());
  }

  /**
   * get parameters as a QueryParams or DeepQueryParams object usable by the fetchers
   */
  get params(): QueryParams | DeepQueryParams {
    return JSON.parse(JSON.stringify(this._params));
  }

  withSlug(slug: string) {
    if (slug) {
      this._params.slug = slug;
    } else {
      delete this._params.slug;
    }

    return this;
  }

  withIds(ids: string[]) {
    if (ids?.length > 0) {
      this._params.include = ids.join(',');
    } else {
      delete this._params.include;
    }

    return this;
  }

  withAllPages(enabled = true) {
    if (enabled) {
      this._params['proxy-cms-pages'] = 'all';
    } else {
      delete this._params['proxy-cms-pages'];
    }

    return this;
  }

  withPreviewMode(enabled = true) {
    if (enabled) {
      this._params['gik-preview'] = 1;
    } else {
      delete this._params['gik-preview'];
    }
    return this;
  }

  withPerPage(perPage: number) {
    this._params.perPage = perPage;
    return this;
  }

  withPage(page: number) {
    this._params.page = page;
    return this;
  }

  byAuthor(authorSlug: string) {
    this._params.filter = this._params.filter || {};
    this._params.filter.author_slug = authorSlug;
    return this;
  }

  withSingleTaxonomyItem(relationFilters: RelationFilter<string>) {
    this._params.filter = {
      tax_query: {
        relation: 'AND',
        0: {
          taxonomy: relationFilters.taxonomy,
          field: relationFilters.field || this.defaultTaxonomyItemFieldValue,
          operator: 'IN',
          include_children: 0,
          terms: relationFilters.terms,
        },
      },
    };

    return this;
  }

  withSingleTaxonomyMultipleItems(relationFilters: RelationFilter<string[]>) {
    this._params.filter = {
      tax_query: {
        relation: 'AND',
        0: {
          taxonomy: relationFilters.taxonomy,
          field: relationFilters.field || this.defaultTaxonomyItemFieldValue,
          operator: 'IN',
          include_children: 0,
          terms: relationFilters.terms,
        },
      },
    };

    return this;
  }

  withMultipleTaxonomiesMultipleItems(relationFilters: RelationFilter<string[]>[]) {
    if (relationFilters) {
      this._params.filter = {
        tax_query: {
          relation: 'AND',
          ...relationFilters?.reduce(
            (acc, relationFilter, i) => ({
              ...acc,
              [i]: {
                taxonomy: relationFilter.taxonomy,
                field: relationFilter.field || this.defaultTaxonomyItemFieldValue,
                operator: 'IN',
                include_children: 0,
                terms: relationFilter.terms,
              },
            }),
            {}
          ),
        },
      };
    }

    return this;
  }

  withMeta(metaKey: string, metaValue: number | string) {
    this._params.meta_key = metaKey;
    this._params.filter = {
      meta_value: metaValue,
      meta_compare: 'LIKE',
    };

    return this;
  }

  withProductBundles(enabled = true) {
    if (enabled) {
      this._params.meta_product_bundles = 1;
    } else {
      delete this._params.meta_product_bundles;
    }

    return this;
  }

  withFields(fields: string[]) {
    if (fields?.length > 0) {
      this._params._fields = fields.join(',');
    }

    return this;
  }

  withBuyingGuides(enabled = true) {
    if (enabled) {
      this._params.meta_buying_guides = 1;
    } else {
      delete this._params.meta_buying_guides;
    }

    return this;
  }

  orderedByDate() {
    this._params.orderby = 'date';
    this._params.order = 'desc';

    return this;
  }

  orderedByPriority() {
    //&filter[orderby][meta_value_num]=desc&filter[orderby][post_date]=desc&filter[meta_key]=priority
    this._params.filter = this._params.filter || {};
    this._params.filter.orderby = {
      meta_value_num: 'desc',
      post_date: 'desc',
    };
    this._params.filter.meta_key = 'priority';

    return this;
  }
}

export default PostsUrlBuilder;
