import { useHasUnbounceBar } from '@gik/core/hooks/UnbouceHooks';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { AppHeader } from '@gik/ui/gik/AppHeader/AppHeader';
import { Sticky } from '@gik/ui/Sticky';
import React from 'react';

export type IAppHeaderStickyProps = {
  navBarAttachment?: React.ReactNode;
};

function AppHeaderStickyComp({ navBarAttachment }: IAppHeaderStickyProps) {
  const bem = useBemCN('app-header-sticky', '');
  const unbounce = useHasUnbounceBar();

  return (
    <Sticky {...bem(null, [{ unbounce }])}>
      <AppHeader />
      {navBarAttachment}
    </Sticky>
  );
}

export const AppHeaderSticky = withComponentErrorBoundary(AppHeaderStickyComp);
