import { isBrowser } from '@/utils/environment';
import type { APIWithFields, APIWithPagination, APIWithSlug, DeepQueryParams } from '@gik/core/api/BaseAPIConfig';
import { wpApi } from '@gik/core/api/ky/wpApi';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import type WordpressPost from '@gik/core/models/wordpress/WordpressPost';
import qs from 'qs';
import type { SWRConfiguration } from 'swr';
import PostsUrlBuilder from './PostsUrlBuilder';

const path = 'posts';

export interface PostsFeaturedParams extends APIWithPagination, APIWithSlug, APIWithFields {}
export const giveHomeFeaturedArticlesParams: PostsFeaturedParams = { slug: 'give-helpful-articles', perPage: 4 };

function getParams(params: PostsFeaturedParams): DeepQueryParams {
  return new PostsUrlBuilder()
    .withPerPage(params.perPage)
    .withSingleTaxonomyItem({
      taxonomy: 'featured-content',
      terms: params.slug,
    })
    .withFields(params.fields).params;
}

export async function getPostsFeatured(params: PostsFeaturedParams) {
  if (isBrowser()) {
    const response = await wpApi.get(path, {
      searchParams: qs.stringify(getParams(params)),
    });

    return (await response.json()) as WordpressPost[];
  } else {
    return wpApi
      .get(path, {
        searchParams: qs.stringify(getParams(params)),
      })
      .json<WordpressPost[]>();
  }
}

export function usePostsFeatured(params: PostsFeaturedParams, config?: SWRConfiguration) {
  return useWPApi<WordpressPost[]>(path, getParams(params), config);
}
