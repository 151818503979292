import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useInkind } from '@gik/api/inkinds/inkind';
import type { IUserLoginResponse } from '@gik/api/users/types';
import ExternalLoginForm from '@gik/auth/components/ExternalLogin/ExternalLoginForm';
import { translationKeys } from '@gik/auth/components/WalletSignInFlow/i18n/en';
import { SignInFlowStartContentFormInputNames } from '@gik/auth/enums/SignInFlowStartContentFormInputNames';
import { signInFlowStartContentFormSchema } from '@gik/auth/formSchemas';
import type { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';
import { verifyEmailAndRedirectToLoginProvider } from '@gik/auth/utils/verifyEmailAndRedirectToLoginProvider';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { Form, FormField } from '@gik/ui/Form';
import { Separator } from '@gik/ui/Separator';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum WalletSignInFlowContentCopyVariant {
  GENERAL = 'general',
  INKIND_PAGE = 'inkind-page',
}

interface IWalletSignInFlowStartFormValues {
  [SignInFlowStartContentFormInputNames.EmailAddress]: string;
}

export type IWalletSignInFlowStartContentProps = UIComponent & {
  routeId?: string;
  goToLoginStep: (email: string, firstName: string) => void;
  goToAccountDeactivatedStep: (email: string, firstName: string) => void;
  goTo2faStep: (email: string) => void;
  goToOAuthStep: (email: string, provider: ExternalLoginProvider, firstName: string) => void;
  initialValues?: IWalletSignInFlowStartFormValues;
  trackingId?: AnalyticsEvents;
  onChange?: (values: IWalletSignInFlowStartFormValues) => void;
  onSuccess(values: IUserLoginResponse);
  onIncorrectProvider?: (email: string) => Promise<void>;
};

export function WalletSignInFlowStartContent({
  routeId,
  goToLoginStep,
  goToAccountDeactivatedStep,
  goToOAuthStep,
  goTo2faStep,
  className,
  trackingId,
  initialValues,
  onChange,
  onSuccess,
  onIncorrectProvider,
  ...otherProps
}: IWalletSignInFlowStartContentProps) {
  const bem = useBemCN('wallet-sign-in-flow-start-content');
  const { t } = useTranslation();
  const { data: inkindPage } = useInkind(routeId);

  async function handleSubmit(values: IWalletSignInFlowStartFormValues) {
    const { [SignInFlowStartContentFormInputNames.EmailAddress]: email } = values;

    await verifyEmailAndRedirectToLoginProvider(
      email,
      goToLoginStep,
      goToAccountDeactivatedStep,
      goTo2faStep,
      goToOAuthStep
    );
  }

  return (
    <section {...bem(null, null, className)} {...otherProps}>
      <h1
        {...bem(
          'title',
          null,
          'tw-font-semibold tw-text-4xl tw-leading-none tw-text-neutral-900 tw-text-center tw-mt-0 tw-mb-3'
        )}
      >
        {t(translationKeys.startContentTitle).toString()}
      </h1>

      <p
        {...bem(
          'copy',
          null,
          'tw-font-normal tw-text-base tw-leading-tight tw-text-neutral-900 tw-text-center tw-mb-8'
        )}
      >
        {t(translationKeys.startContentCopyGeneral, { pagename: inkindPage?.title }).toString()}
      </p>

      <Form
        {...bem('form')}
        schema={signInFlowStartContentFormSchema}
        trackingId={trackingId}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onChange={onChange}
        render={({ isSubmitting, isValid }) => {
          return (
            <>
              <fieldset>
                <FormField name={SignInFlowStartContentFormInputNames.EmailAddress} />
              </fieldset>

              <Button
                {...bem('btnSubmit')}
                variant="primary"
                type="submit"
                size="lg"
                loading={isSubmitting}
                disabled={!isValid}
                fullWidth
              >
                Next
              </Button>
            </>
          );
        }}
      />

      <Separator variant="neutral-300" thickness="thin">
        <span className="tw-text-neutral-700">or</span>
      </Separator>
      <div className="tw-text-neutral-700 tw-font-bold tw-text-center tw-mb-2 tw--mt-4">Continue with:</div>

      <ExternalLoginForm vertical onSuccess={onSuccess} onIncorrectProvider={onIncorrectProvider} />
    </section>
  );
}
