import { isBrowser } from '@/utils/environment';
import { breakpointBem } from '@gik/core/hooks/hooks/BreakpointHooks';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { IDrawerProps } from '@gik/ui/Drawer/DrawerTypes';
import type { IAppHeaderProps } from '@gik/ui/gik/AppHeader/AppHeader';
import type { IAppNavMobileProps } from '@gik/ui/gik/AppNavMobile';
import { SvgIcon } from '@gik/ui/SvgIcon';
import MenuIcon from '@heroicons/react/solid/MenuIcon';
import dynamic from 'next/dynamic';
import React from 'react';

const Drawer = dynamic<IDrawerProps>(() => import('@gik/ui/Drawer/Drawer').then(mod => mod.Drawer));
const AppNavMobile = dynamic<IAppNavMobileProps>(() =>
  import('@gik/ui/gik/AppNavMobile').then(mod => mod.AppNavMobile)
);

function AppHeaderNavMobileDrawerComp({ breakpoint }: IAppHeaderProps) {
  const bem = useBemCN('app-header-nav-mobile-drawer');
  const mobileSearchRef = React.useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  function handleOpenMobileNav() {
    setIsOpen(true);
  }

  return (
    <>
      <Drawer
        {...bem(null, breakpointBem(breakpoint))}
        fixed
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        portal={isBrowser() ? document.body : null}
      >
        <AppNavMobile searchRef={mobileSearchRef} onClose={() => setIsOpen(false)} />
      </Drawer>

      <a {...bem('nav-mobile-trigger', breakpointBem(breakpoint))} onClick={handleOpenMobileNav}>
        <SvgIcon Icon={MenuIcon} />
      </a>
    </>
  );
}

export const AppHeaderNavMobileDrawer = withComponentErrorBoundary(AppHeaderNavMobileDrawerComp);
