import type { BreakpointType } from '@gik/core/hooks/hooks/BreakpointHooks';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { AppHeaderLogo } from '@gik/ui/gik/AppHeader/AppHeaderLogo';
import { AppHeaderNav } from '@gik/ui/gik/AppHeader/AppHeaderNav';
import { AppHeaderNavBar } from '@gik/ui/gik/AppHeader/AppHeaderNavBar';
import { AppHeaderNavMobile } from '@gik/ui/gik/AppHeader/AppHeaderNavMobile';
import { AppHeaderNavMobileDrawer } from '@gik/ui/gik/AppHeader/AppHeaderNavMobileDrawer';
import React from 'react';

export type IAppHeaderProps = {
  muted?: boolean;
} & BreakpointType;

function AppHeaderComp({ breakpoint, muted }: IAppHeaderProps) {
  return (
    <AppHeaderNavBar muted={muted} breakpoint={breakpoint}>
      <AppHeaderNavMobileDrawer breakpoint={breakpoint} />
      <AppHeaderLogo breakpoint={breakpoint} />
      <AppHeaderNavMobile breakpoint={breakpoint} />
      <AppHeaderNav breakpoint={breakpoint} />
    </AppHeaderNavBar>
  );
}

export const AppHeader = withComponentErrorBoundary(AppHeaderComp);
