import { breakpointBem } from '@gik/core/hooks/hooks/BreakpointHooks';
import { useBemCN } from '@gik/core/utils/bemBlock';
import type { IAppHeaderProps } from '@gik/ui/gik/AppHeader/AppHeader';
import { NavBar, useNavBarStore } from '@gik/ui/NavBar';
import React from 'react';

export function AppHeaderNavBar({ children, muted, breakpoint }: React.PropsWithChildren<IAppHeaderProps>) {
  const bem = useBemCN('app-header-nav-bar');
  const isNavBarBlurred = useNavBarStore(state => state.blurred);

  return (
    <NavBar
      contained
      {...bem(null, [breakpointBem(breakpoint), { muted }])}
      blurred={isNavBarBlurred}
      breakpoint={breakpoint}
    >
      {children}
    </NavBar>
  );
}
